<template>
  <div class="animated fadeIn">
    <CRow>
      <el-form ref="queryForm" :model="queryForm" label-width="100px" :inline="true">
        <el-form-item label="關鍵字">
          <el-input v-model="queryForm.qstr" placeholder="帳號、名稱、電子郵件"></el-input>
        </el-form-item>
        <el-form-item label="所屬單位">
            <el-select v-model="queryForm.organization" filterable placeholder="請選擇" clearable="">
              <el-option
                v-for="item in organizations"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="currentPage=1;query(pageSize,0, sort, order, queryForm)">查詢</el-button>
          <el-button type="primary" icon="el-icon-plus" @click="addUser" >新增</el-button>
        </el-form-item>
      </el-form>

    </CRow>
    <CRow>
    <el-table
           
            :data="users"
            style="width:100%"
            border
            stripe
            empty-text="暫無數據"
            :default-sort = "{prop: 'dateCreated', order: 'descending'}"
             @sort-change = "sortChange"
          >
           <el-table-column
              type="index"
              :index="indexFormatter"
              fixed
              >
            </el-table-column>
            <el-table-column
                label="帳號"
                prop="username"
                sortable = "custom"
            >
            </el-table-column>
            <el-table-column
                label="名稱"
                prop="name"
                sortable = "custom"
            >
            </el-table-column>
            <el-table-column
                label="電子郵件"
                prop="email"
                sortable = "custom"
            >
            </el-table-column>
            <el-table-column
                label="權限"
                prop="role"
                sortable = "custom"
                :formatter="isAdminFormatter"
            >
            </el-table-column>
            <el-table-column
                label="負責管理單位"
                prop="organization.name"
            >
            </el-table-column>
            <el-table-column
              fixed="right"
              label="操作"
              width="150">
              <template slot-scope="scope">
                <el-button
                @click.native.prevent="editUser(scope.row.id)"
                  size="mini">
                  編輯
                </el-button>
                <el-button
                @click.native.prevent="deleteUser(scope.row.id)"
                v-if="user_id != scope.row.id"
                  type="danger"
                  size="mini">
                  刪除
                </el-button>
              </template>
          </el-table-column>
          </el-table>

    </CRow>
    <CRow>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-sizes="[10, 25, 50]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
    </CRow>
 
  <el-dialog title="帳號" :visible.sync="dialogFormVisible">
      <el-form :model="form" ref="form" :rules="rules">
        <el-form-item label="帳號" :label-width="formLabelWidth" prop="username">
          <el-input v-model="form.username" autocomplete="off" :disabled="form.id != null"></el-input>
        </el-form-item>
        <el-form-item label="密碼" prop="password" :label-width="formLabelWidth">
          <el-input type="password" v-model="form.password" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="確認密码" prop="checkPassword" :label-width="formLabelWidth">
          <el-input type="password" v-model="form.checkPassword" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="名稱" :label-width="formLabelWidth" prop="name">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="電子郵件" :label-width="formLabelWidth" prop="email">
          <el-input v-model="form.email" type="email" autocomplete="off"></el-input>
        </el-form-item>
            <el-form-item label="權限" :label-width="formLabelWidth" prop="role">
            <el-radio-group v-model="form.role">
              <el-radio label="admin">超級管理員</el-radio>
              <el-radio label="org-admin">單位管理員</el-radio>
              <el-radio label="org-member">單位組員</el-radio>
            </el-radio-group>
        </el-form-item>
        <el-form-item label="負責管理單位" :label-width="formLabelWidth" prop="organization">
           <el-select v-model="form.organization" filterable placeholder="請選擇" :disabled="form.role === 'admin'">
              <el-option
                v-for="item in organizations"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">確 定</el-button>
      </div>
  </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Vue from 'vue'
import moment from "moment-timezone";
import API from '@/api/'
Vue.filter('converDate',function(value){
  if(value){
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return moment(value).tz(tz).format('YYYY-MM-DD');
  }else{
    return value;
  }

})
export default {
  name: 'UserList',
  created () {
      this.$store.dispatch('user/list', {max: this.max, offset: this.offset}, {
          root: true
      });
  },
  data() {
          var validatePass = (rule, value, callback) => {
            if (value === '' && this.form.id == null) {
              callback(new Error('請輸入密碼'));
            } else {
              if (this.form.checkPassword !== '') {
                this.$refs.form.validateField('checkPassword');
              }
              callback();
            }
          };
          var validatePass2 = (rule, value, callback) => {
            if (value === '' && this.form.id == null) {
              callback(new Error('請再次輸入密碼'));
            } else if (value !== this.form.password) {
              callback(new Error('兩次輸入密碼不一致!'));
            } else {
              callback();
            }
          };
          var validateUserName = (rule, value, callback) => {
            if (this.form.id) {
                 const data = this.getUserById(this.form.id);
                 if ( data.username === value) {
                   callback();
                   return;
                 }
            } 
            API.user.search({username: value}).then(res =>{
              if (res.data.length == 0) {
                  callback();
              } else {
                callback(new Error('已存在'));
              } 
            })
        };
        return {
          pageSize:10,
          currentPage:0,
          queryForm: {
            qstr: '',
            organization: []
          },
          form: {
            id: null,
            username:'',
            password: '',
            checkPassword:'',
            name:'',
            email:'',
            organization:'',
            is_admin: false,
            role: ''
          },
          dialogFormVisible: false,
          formLabelWidth: '120px',
          rules: {
            username: [
              { required: true, message: '請輸入帳號', trigger: 'blur' },
              { validator: validateUserName, trigger: 'blur' },
            ],
            name: [
              { required: true, message: '請輸入名稱', trigger: 'blur' },
            ],
            email: [
              { required: true, message: '請輸電子郵件', trigger: 'blur' },
              { type: 'email', message: '請輸入正件的電子郵件', trigger: ['blur', 'change'] }
            ],
            role: [
              { required: true, message: '請設定權限', trigger: 'blur' },
            ],
            password: [
              { validator: validatePass, trigger: 'blur' },
              
            ],
            checkPassword: [
              { validator: validatePass2, trigger: 'blur' }
            ],
          },
        }
  },
  methods:{
    clearForm() {
      this.form.id = null;
      this.form.username = '';
      this.form.name = '';
      this.form.email = '';
      this.form.organization = null;
      this.form.is_admin = false;
      this.form.password='';
      this.form.checkPassword='';
      this.form.role = '';
    },
    addUser() {
      if(this.$refs.form){
        this.$refs.form.resetFields();
      }
       this.clearForm();
      this.$store.dispatch('organization/list', {max: 1000, offset: 0}, {
          root: true
      });
      this.dialogFormVisible = true
    },
    isAdminFormatter(row, column, cellValue){
      switch(cellValue){
        case 'admin':
          return "超級管理員"
        case 'org-admin':
          return "單位管理員"
        case 'org-member':
          return "單位組員"
        default:
          return cellValue
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.query(val,this.offset, this.sort, this.order, this.queryForm);
      },
      handleCurrentChange(val) {
        this.query(this.pageSize,this.pageSize*(val-1), this.sort, this.order, this.queryForm);

      },
    query(max,offset,sort, order, param = {}) {
      this.$store.dispatch('user/list', {max: max, offset: offset, sort , order, ...param}, {
          root: true
      });
    },
    indexFormatter(index){
     return `#${parseInt(this.offset)+ index + 1}`;

    },
    dateFormatter(row, column, cellValue){
      if(cellValue){
        const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

        return moment(parseInt(cellValue)).tz(tz).format('YYYY-MM-DD');
        //return moment(cellValue).format('YYYY-MM-DD');
      }else{
        return cellValue;
      }

    },
     editUser(user_id) {
       if (this.$refs['form']) {
        this.$refs['form'].resetFields();
      }
       this.clearForm();
      
       const data = this.getUserById(user_id);
       this.form.id=data.id;
       this.form.username=data.username;
        this.form.name=data.name;
        this.form.email=data.email;
        this.form.role=data.role;
        if(data.organization){
          this.form.organization=data.organization.id;
        } else {
          this.form.organization = null;
        }
        this.form.is_admin= data.is_admin == 1;
      this.$store.dispatch('organization/list', {max: 1000, offset: 0}, {
          root: true
      });
       this.dialogFormVisible = true;
    },
    deleteUser(user_id) {
      this.$confirm('此操作將永久刪除該帳號, 是否繼續?', '提示', {
          confirmButtonText: '確定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$store.dispatch('user/remove', user_id , {
            root: true
          }).then( res => {
            this.query(this.pageSize,this.offset, this.sort, this.order, this.queryForm)
            this.$message({
            type: 'success',
            message: '删除成功!'
          });
          })
          
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });

    },
    submit() {
        this.$refs['form'].validate((valid) => {
          if (valid) {
              if(this.form.id) {
                this.submitUpdate();
              } else {
                this.submitAdd();
              }
          } else {
            return false;
          }
        });
    },
    submitAdd() {
      this.$store.dispatch('user/add', this.form , {
          root: true
      }).then( res => {
        this.dialogFormVisible = false;
        this.clearForm();
        this.query(this.pageSize,this.offset, this.sort, this.order, this.queryForm)
      })
    },
    submitUpdate() {
      this.$store.dispatch('user/update', this.form , {
          root: true
      }).then( res => {
        this.dialogFormVisible = false;
        this.clearForm();
        this.query(this.pageSize,this.offset, this.sort, this.order, this.queryForm)
      })
    },
    sortChange(args) {
      if (args.order === null) {
        this.$store.commit('user/SET_SORT','id',{root: true})
        this.$store.commit('user/SET_ORDER','ascending',{root: true})
      } else {
        this.$store.commit('user/SET_SORT',args.prop,{root: true})
        this.$store.commit('user/SET_ORDER',args.order,{root: true})
      }
      
      this.query(this.pageSize,this.offset,this.sort,this.order)
    },
  },
  computed: {
      ...mapGetters({
          // 取得資料夾列表
          users: 'user/users',
          total:'user/total',
          max:'user/max',
          offset:'user/offset',
          getUserById: 'user/getUserById',
          organizations: 'organization/organizations',
          user_id: 'auth/user_id',
          sort: 'user/sort',
          order: 'user/order'
      })
  },
}
</script>
